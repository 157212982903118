.account-screen {
    height: 100dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.account-info-header {
    width: calc(100% - 100px);
    margin-left: 50px;
    padding: 30px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
@media (max-width: 850px) {
    .account-info-header {
        width: calc(100% - 20px);
        margin-left: 10px;
        padding: 10px 0;
    }
}

@media (max-width: 850px) {
    .account-info-header .image-drop-area, .account-info-header .preview-image {
        height: 100px !important;
        width: 100px;
    }
}

.account-info-column {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
@media (max-width: 850px) {
    .account-info-column {
        height: 100px;
    }
}
.account-editable-username {
    font-size: 40px;
    font-weight: bold;
    color: var(--primary-text-color);
    font-family: "Inter", sans-serif;
    margin-left: 20px;
    cursor: pointer;
    letter-spacing: -1px;
}
.account-editable-username:focus, .account-editable-url:focus {
    outline: none;
}
.account-username {
    font-size: 40px;
    font-weight: bold;
    color: var(--primary-text-color);
    margin:  0 0 0 20px;
    cursor: pointer;
    border: 2px dotted var(--soft-border-color);
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: fit-content;
}

@media (max-width: 850px) {
    .account-editable-username, .account-username {
        font-size: 20px;
        margin-left: 10px;
    }
}

.account-profile-url-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0 0 20px;
    gap: 10px;
}
.account-profile-url-container img {
    height: 25px;
    filter: invert(1);
}
.account-profile-url-container p {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-text-color);
    border: 2px dotted var(--soft-border-color);
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
@media (max-width: 850px) {
    .account-profile-url-container {
        margin: 5px 0 0 10px;
    }

    .account-profile-url-container img {
        height: 20px;
    }
    .account-profile-url-container p {
        font-size: 15px;
    }
}
.account-editable-url {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-text-color);
    font-family: 'Inter', sans-serif;
    letter-spacing: -1px;
}
@media (max-width: 850px) {
    .account-editable-url {
        font-size: 15px;
    }
}
.account-stats-row {
    border: 1px solid var(--soft-border-color);
    margin: 10px 0 0 20px;
}

@media (max-width: 850px) {
    .account-stats-row {
        gap: 10px;
        margin: 5px 0 0 10px;
    }
    .account-stats-row .creator-share-pill {
        display: none;
    }
}

.change-password-button {
    margin: 10px 0 0 20px;
    padding: 5px 20px;
    border-radius: 5px;
    color: var(--primary-text-color);
    border: 1px solid var(--soft-border-color);
    background-color: transparent;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    width: fit-content;
}

@media (max-width: 850px) {
    .change-password-button {
        display: none;
    }
}

.password-modal {
    position: fixed;
    top: 40%;
    height: fit-content;
    width: 25%;
    left: 37%;
    background-color: var(--accent-background-color);
    border-radius: 5px;
    border: 1px solid var(--soft-border-color);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.password-modal-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    gap: 10px;
}

.password-modal h2 {
    font-size: 20px;
    color: var(--primary-text-color);
    margin: 0;
}
.password-modal-content input{
    width:50%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--soft-border-color);
    background-color: var(--background-color);
    color: var(--primary-text-color);
}

.password-modal-content input:focus {
    outline: none;
}
.password-modal-content button {
    width: 55%;
    padding: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: var(--primary-text-color);
    border: 1px solid var(--soft-border-color);
    cursor: pointer;
}
.submit-change-password {
    background-color: #109157 !important;
}
