.top-models {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;
    max-width: 50%;
    border-radius: 10px;
    background-color: rgba(54, 37, 30, 0.8);
}
@media screen and (max-width: 850px) {
    .top-models {
        padding: 0 20px;
        max-width: none;
    }
}

.top-models-title {
    margin: 0;
    font-size: 22px;
    color: var(--primary-text-color);
    font-weight: bold;
}

@media screen and (max-width: 850px) {
    .top-models-title {
        font-size: 19px;
        margin: 25px 0 10px 0;
        word-spacing: -2px;
        letter-spacing: -1px;
    }
}
