.search-bar {
  width: 85%;
  margin-bottom: 20px;
}

.search-bar label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--primary-text-color);
}

.search-bar input {
  width: 100%;
  padding: 10px;
  padding-left: 40px;
  border: 1px solid var(--soft-border-color);
  border-radius: 10px;
  font-size: 0.85rem;
  background-color: var(--background-color);
  color: var(--primary-text-color);
  background-image: url('../../assets/icons/searchIcon.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px 20px;
}
.search-bar input:focus {
  outline: none;
}
.search-bar input::placeholder {
  font-style: italic;
  font-size: 0.85rem;
  color: var(--secondary-text-color);
}
