.creator-screen {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
    background-color: var(--background-color);
}
@media (max-width: 850px) {
    .creator-screen {
        height: 100dvh;
        width: 100vw;
        flex-direction: column;
        overflow-y: scroll;
        scrollbar-width: none;
        min-height: 100dvh;
        overflow-x: hidden;
    }
}

.creator-screen-artist-details {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    background: rgba(30, 40, 54, 0.8);
    background-size: cover;
    padding: 10px 0;
    margin: 10px;
    border-radius: 15px;
}
.creator-screen-cover {
    position: absolute;
    height: 250px;
    width: 100%;
    object-fit: cover;
}
@media (max-width: 850px) {
    .creator-screen-artist-details {
        width: calc(100% - 20px);
        height: fit-content;
        flex-direction: column;
        justify-content: center;
    }
}
.creator-screen-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
    width: calc(100% - 20px);
    margin: 0 10px;
    border-radius: 15px;
    background-color: var(--accent-background-color);
}
@media (max-width: 850px) {
    .creator-screen-content {
        flex-direction: column;
        padding-bottom: 100px;
        height: fit-content;
    }
}

.creator-go-back {
    width: fit-content;
    padding: 20px;
    background-color: transparent;
    color: var(--primary-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 15px;
}
.creator-go-back:hover {
    cursor: pointer;
}
@media (max-width: 850px) {
    .creator-go-back {
        font-size: 13px;
    }
}

.creator-name {
    font-size: 50px;
    color: var(--primary-text-color);
    margin: 0 0 0 40px;
}
@media (max-width: 850px) {
    .creator-name {
        margin: 20px 10px 0px 20px;
        font-size: 25px;
    }
}
.creator-creator {
    font-size: 40px;
    color: var(--secondary-text-color);
    margin: 0 0 0 40px;
}
.creator-details-with-cover {
    display: flex;
    flex-direction: row;
    margin: 10px 0 10px 40px;
    align-items: flex-end;
}
.creator-details-with-cover .creator-pfp{
    height: 150px;
    width: 150px;
    object-fit: cover;
    object-position: center;
    border-radius: 15px;
}

.creator-details-with-cover .screen-link-pill {
    margin-left: 20px !important;
}
.creator-details-with-cover .creator-name {
    margin-left: 20px;
}
.creator-details-with-cover .creator-links-row {
    margin: 5px 0 0 0;
}
@media (max-width: 850px) {
    .creator-details-with-cover {
        margin: 0px 0 10px 20px;
    }
    .creator-details-with-cover .creator-pfp{
        height: 100px;
        width: 100px;
        min-width: 100px;
    }
    .creator-details-with-cover .screen-link-pill {
        margin-left: 10px !important;
    }
    .creator-details-with-cover .creator-name {
        margin-top: 0px;
        font-size: 20px;
        margin-left: 10px;
    }
}
.creator-links-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    justify-content: flex-start;
}
@media (max-width: 850px) {
    .creator-links-row {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
.creator-screen-download-button {
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    border-radius: 5px;
    border: none;
    color: var(--primary-text-color);
}
.creator-screen-download-button:hover {
    cursor: pointer;
}
.creator-screen-download-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.model-screen-link-pill {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: fit-content;
    gap: 10px;
    background-color: var(--background-color);
    border-radius: 10px;
}
.model-screen-link-pill:hover{
    cursor: pointer;
}
.model-screen-link-pill img{
    height: 20px;
}
@media (max-width: 850px) {
    .model-screen-link-pill img{
        height: 17.5px;
    }
}
.creator-share-pill {
    display: flex;
    height: 40px;
    background-color: var(--background-color);
    border-radius: 10px;
    align-items: center;
    justify-content: center;
}
.creator-share-pill:hover {
    cursor: pointer;
}
.creator-share-pill img {
    height: 20px;
    padding: 12.5px;
}
@media (max-width: 850px) {
    .creator-share-pill img {
        height: 20px;
        padding: 10px;
    }
}
.creator-share-pill span {
    font-size: 13px;
    color: var(--primary-text-color);
    padding-right: 12.5px;
}

.creator-stats-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 40px;
    height: 20px;
    margin-top: 10px;
    margin-left: 40px;
    padding: 10px 20px;
    width: fit-content;
    background-color: var(--background-color);
    border-radius: 10px;
}
@media (max-width: 850px) {
    .creator-stats-row {
        margin-top: 0;
        margin-left: 20px;
        gap: 20px;
    }
}
.creator-stats-row p, .creator-stats-row h6 {
    margin: 0;
}
.creator-stats-row h1 {
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-text-color);
}
.creator-stats-row p {
    font-size: 15px;
    color: var(--primary-text-color);
}
@media (max-width: 850px) {
    .creator-stats-row h1 {
        font-size: 15px;
    }
    .creator-stats-row p {
        font-size: 10px;
    }
}
.creator-stats-pill {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 10px;
}
.creator-stats-pill h1, .creator-stats-pill p {
    margin: 0;
}

.creator-screen-content-section {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
}
@media (max-width: 850px) {
    .creator-screen-content-section {
        padding-left: 0px;
    }
}
.creator-screen-content-section h1{
    margin: 15px 0 0 15px;
    color: var(--primary-text-color);
    font-weight: normal;
    font-size: 20px;
}
