.home-remix-banner {
    width: calc(100% - 140px);
    margin: 0 50px 20px 50px;
    background-color: rgba(30, 40, 54, 0.8);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 60px 30px;
    gap: 50px;
}
@media (max-width: 850px) {
    .home-remix-banner {
        width: calc(100% - 60px);
        margin: 0 20px 20px 20px;
        padding: 20px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
}

.remix-banner-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
@media (max-width: 850px) {
    .remix-banner-info {
        gap: 5px;
    }
}
.home-remix-banner-title {
    font-size: 50px;
    background: -webkit-linear-gradient(#98e4fb, #8c4ef8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
}
@media (max-width: 850px) {
    .home-remix-banner-title {
        font-size: 24px;
        word-spacing: -2px;
        letter-spacing: -1px;
    }
}
.home-remix-banner-subtitle {
    margin: 0;
    font-size: 20px;
    color: var(--primary-text-color);
}
@media (max-width: 850px) {
    .home-remix-banner-subtitle {
        font-size: 15px;
    }
}
.remix-banner-gifs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
@media (max-width: 850px) {
    .remix-banner-gifs {
        margin-top: 20px;
        flex-direction: row;
    }
}
.remix-banner-gifs img {
    height: 50px;
}

.make-remix-button {
    align-items: center;
    margin-top: 60px;
    background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
    border: 0;
    height: 50px;
    margin-left: -40px;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-family: Phantomsans, sans-serif;
    font-size: 15px;
    justify-content: center;
    line-height: 1em;
    width: 160px;
    min-width: 160px;
    padding: 1px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
}
@media (max-width: 850px) {
    .make-remix-button {
        margin: 0;
    }
}

.make-remix-button:active,
.make-remix-button:hover {
    outline: 0;
}

.make-remix-button span {
    background-color: rgb(5, 6, 45);
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: 300ms;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.make-remix-button:hover span {
    background: none;
}

@media (min-width: 768px) {
    .button-64 {
        font-size: 24px;
        min-width: 196px;
    }
}
