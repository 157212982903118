.model-tile {
    height: 100%;
    border-radius: 15px;
    display: flex;
    padding-right: 15px;
    flex-direction: row;
    justify-content: flex-start;
    background-color: var(--background-color);
    /*border: 1px solid var(--secondary-text-color);*/
}
@media (max-width: 850px) {
    .model-tile {
        margin-right: 20px;
    }
}
.model-tile:hover {
    /*cursor: pointer;*/
}

.model-info-section {
    height: calc(100% - 40px);
    aspect-ratio: 1;
    padding: 20px 0 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
}
.model-info-section p {
    margin: 0;
}
.model-tile-descriptor {
    font-size: 13px;
    color: var(--secondary-text-color)
}
@media (max-width: 850px) {
    .model-tile-descriptor {
        font-size: 3vw;
    }
}
.model-tile-title {
    color: var(--primary-text-color);
    font-weight: bold;
    margin-bottom: 5px;
}
.model-tile-title:hover {
    cursor: pointer;
    text-decoration: underline;
}
@media (max-width: 850px) {
    .model-tile-title {
        font-size: 4vw;
    }
}
.model-tile-second-info {
    color: var(--secondary-text-color);
    font-weight: normal;
}

.model-tile-actions-row {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.model-tile-actions-row button {
    color: var(--primary-text-color);
    border: 1px solid var(--secondary-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    border-radius: 8px;
    height: 40px;
}
.model-tile-actions-row button:hover {
    cursor: pointer;
}
.model-tile-download {
    background-color: var(--active-button);
}
.model-tile-upvote {
    padding: 0 25px !important;
    background-color: transparent;
}
.model-tile-upvote:hover {
    background-color: var(--accent-background-color);
}
.model-tile-upvote img {
    width: 20px;
    height: 20px;
}

.model-song-tile {
    height: calc(100% - 40px);
    width: 170px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
@media (max-width: 850px) {
    .model-song-tile {
        width: 150px;
        height: calc(100% - 20px);
        padding: 10px;
    }
}
.model-song-tile-cover {
    width: 150px;
    aspect-ratio: 1 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
}
@media (max-width: 850px) {
    .model-song-tile-cover {
        width: 150px;
        aspect-ratio: 1 !important;
    }
}
.model-song-tile-cover:hover {
    cursor: pointer;
}
.model-song-tile-cover:hover .high-five-play-button {
    transition: 0.2s;
    background-color: #ff2e2e;
}
.model-song-title {
    color: var(--primary-text-color);
    font-weight: bold;
    margin: 10px 0 0 0;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.model-song-title:hover {
    cursor: pointer;
    text-decoration: underline;
}
@media (max-width: 850px) {
    .model-song-title {
        font-size: 3.5vw;
    }
}
.model-song-artist {
    font-size: 14px;
    color: var(--secondary-text-color);
    margin: auto 0 0 0;
    text-decoration: underline;
}
.model-song-artist:hover {
    cursor: pointer;
}
@media (max-width: 850px) {
    .model-song-artist {
        font-size: 3vw;
    }
}

