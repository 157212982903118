.player {
    width: calc(100vw - 80px);
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(0,0,0,0.7);
    backdrop-filter: blur(10px);
    padding: 0 40px;
    z-index: 99999;
    overflow: hidden;
    position: absolute;
    bottom: 0;
}
.invisible-player {
    opacity: 0 !important;
    pointer-events: none !important;
}
@media (max-width: 850px) {
    .player {
        position: sticky;
        top: auto;
        left: 10px;
        padding: 10px;
        height: 60px;
        width: calc(100% - 20px);
    }
}

.player-player {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
@media (max-width: 850px) {
    .player-player {
        flex-direction: row;
        justify-content: space-between;
    }
}
.player-song-info-container {
    display: flex;
    flex: 1;
    height: 60px;
    flex-direction: row;
}
@media (max-width: 850px) {
    .player-song-info-container {
        flex: 1;
        min-width: 75%;
    }
}
.player-cover-container {
    background-position: center;
    height: 100%;
    border-radius: 5px;
    aspect-ratio: 1/1;
}
.player-text-info-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    align-items: flex-start;
    margin-left: 10px;
}
@media (max-width: 850px) {
    .player-text-info-container {
        margin-left: 10px;
        flex: 1;
    }
}

.player-text-info-container p, .player-text-info-container h6 {
    color: var(--primary-text-color);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    margin: 0;
}
@media (max-width: 850px) {
    .player-text-info-container p {
        --webkit-line-clamp: 2;
    }
}
.player-text-info-container p {
    font-size: 14px;
    font-weight: bold;
}
.player-text-info-container p:hover {
    cursor: pointer;
    text-decoration: underline;
}
.player-text-info-container h6 {
    font-size: 14px;
    font-weight: normal;
    color: var(--secondary-text-color);
    text-decoration: underline;
}
.player-text-info-container h6:hover {
    cursor: pointer;
}
@media (max-width: 850px) {
    .player-text-info-container p {
        font-size: 15px;
    }
    .player-text-info-container h6 {
        font-size: 12px;
    }
}

.player-controls-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    gap: 5px;
    flex-direction: column;
    justify-content: center;
}
@media (max-width: 850px) {
    .player-controls-container {
        justify-content: flex-end;
        flex: auto;
        margin-top: 0px;
        margin-right: 30px;
        flex-direction: row;
        align-items: center;
    }
}
.shorter-player-controls-container {
    height: 60px !important;
}
@media (max-width: 850px) {
    .shorter-player-controls-container {
        justify-content: flex-end;
    }
}
.player-remix-container {
    margin: 5px 10px;
    min-height: 40px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 3px 10px;
    border-radius: 10px;
    background-color: var(--fun-object-background);
    border: 1px solid var(--primary-text-color);
}
.player-remix-container-mobile {
    margin: 5px 0;
    width: fit-content;
    padding: 3px 0;
}
.player-remix-container-mobile img {
    padding: 0 !important;
}
@media (max-width: 850px) {
    .player-remix-container-desktop {
        display: none;
    }
}
@media (min-width: 850px) {
    .player-remix-container-mobile {
        display: none;
    }
}
@media (max-width: 850px) {
    .player-remix-container {
        background-color: rgba(0,0,0,0);
        border: none;
        gap: 0;
    }
}
.player-remix-container:hover {
    cursor: pointer;
}
.player-remix-container p {
    margin: 0;
    color: var(--primary-text-color);
    font-size: 14px;
}
@media (max-width: 850px) {
    .player-remix-container p{
        display: none;
    }
    .player-remix-container img {
        padding: 0 10px;
        height: 25px;
        margin-right: 5px
    }
}

.player-media-controls-container {
    display: flex;
    flex-direction: row;
    height: 30px;
    width: 100%;
    justify-content: center;
    align-items: center;
}
@media (max-width: 850px) {
    .player-media-controls-container {
        width: 50px;
        justify-content: center;
    }
}
.player-media-controls-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: center;
}
@media (max-width: 850px) {
    .player-media-controls-container {
        height: 100%;
    }
    .player-media-controls-center {
        gap: 5px;
    }
}
.player-play-button {
    background-color: #FF4E4E;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 5px;
}
@media (max-width: 850px) {
    .player-play-button {
        height: 35px;
        width: 35px;
    }
}
.player-play-button:hover {
    cursor: pointer;
}
.player-heart-button {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    width: 40px;
}
.player-heart-button img {
    height: 25px;
}
.player-heart-button img:hover {
    cursor: pointer;
}
.player-previous-button img {
    height: 30px;
}
.player-previous-button img:hover {
    cursor: pointer;
}
@media (max-width: 850px) {
    .player-previous-button img {
        display: none;
    }
}
.player-play-button img {
    height: 25px;
    filter: invert(1);
}
.player-next-button img:hover {
    cursor: pointer;
}
.player-next-button img {
    height: 30px;
}
@media (max-width: 850px) {
    .player-play-button img {
        margin-right: 0;
    }
    .player-heart-button img {
        display: none;
    }
}

.player-time-controls {
    height: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
@media (max-width: 850px) {
    .player-time-controls {
        display: none;
    }
}
.player-time-controls p {
    font-size: 13px;
    margin: 0 5px;
    color: var(--primary-text-color);
}

.player-time-bar-wrapper {
    padding: 6px 0;
    width: 80%;
    height: 5px;
}
.player-time-bar-wrapper:hover {
    cursor: pointer;
}

.player-time-bar-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--active-button);
    border-radius: 2.5px;
}

.player-time-bar {
    height: 5px;
    width: 100%;
    border-radius: 2.5px;
    background-color: var(--primary-text-color);
}


.player-model-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    height: 100%;
    justify-content: flex-end;
}
.player-model-container {
    display: flex;
    flex-direction: column;
    height: 40px;
    padding: 5px 20px;
    border-radius: 10px;
    align-items: flex-start;
    width: 220px;
    justify-content: center;
    background-color: var(--accent-background-color);
    border: 1px solid var(--soft-border-color);
}
.player-model-container:hover {
    cursor: pointer;
}
@media (max-width: 850px) {
    .player-model-container {
        display: none;
    }
}
.player-model-container h5, .player-model-container h2 {
    margin: 0;
}
.player-model-container h5 {
    color: var(--secondary-text-color);
    font-size: 12px;
    font-weight: normal;
}
.player-model-container h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: var(--primary-text-color);
    font-size: 14px;
    font-weight: bold;
}
