.login-modal {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    background-color: var(--accent-background-color);
    border-radius: 5px;
    flex-direction: column;
    z-index: 99999999999999;
    padding: 20px;
    border: 1px solid var(--soft-border-color);
}
@media (max-width: 850px) {
    .login-modal {
        width: 80%;
        height: 80%;
    }
}
.login-modal-close {
    font-size: 14px;
    color: var(--secondary-text-color);
    cursor: pointer;
}
.login-modal-name {
    color: var(--primary-text-color);
    font-size: 20px;
    margin: 0;
}
.login-modal-title {
    color: var(--primary-text-color);
    font-size: 30px;
    margin: 10px 0 ;
}
.other-signin-option {
    cursor: pointer;
    margin: 0;
    color: var(--secondary-text-color);
}
.login-text-entry {
    width: 80%;
    height: 30px;
    padding: 10px;
    color: var(--primary-text-color);
    border-radius: 6px;
    background-color: var(--background-color);
    border: none;
}
.login-text-entry:focus {
    outline: none;
    background-color: var(--background-color);
}
.login-submit {
    background-color: var(--active-button);
    border: none;
    padding: 15px 25px;
    color: var(--primary-text-color);
    border-radius: 5px;
    cursor: pointer;
}
