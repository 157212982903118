.horizontal-song-tile {
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.horizontal-song-rank{
    font-size: 20px;
    margin-right: 20px;
    color: var(--secondary-text-color);
}
@media (max-width: 850px) {
    .horizontal-song-rank {
        font-size: 15px;
        margin-right: 10px;
    }
}
.horizontal-song-cover {
    height: 90%;
    border-radius: 6px;
    aspect-ratio: 1;
    background-size: cover;
    background-position: center;
    align-items: center;
    justify-content: center;
    display: flex;
}
.horizontal-song-cover .top-song-play-button img {
    height: 15px;
    width: 15px;
}
.horizontal-song-info {
    width: 400px;
    margin-left: 10px;
}
.horizontal-song-stats {
    display: flex;
    flex-direction: row;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}
.horizontal-song-model-name {
    margin: 0 10px;
    color: var(--secondary-text-color);
    font-size: 14px;
    width: 200px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-width: 200px;
}
@media (max-width: 850px) {
    .horizontal-song-model-name {
        min-width: auto;
        width: fit-content;
        display: none;
    }
}
.horizontal-song-model-name:hover {
    cursor: pointer;
    text-decoration: underline;
}
.horizontal-song-stream-count {
    margin: 0 10px;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: var(--secondary-text-color);
    font-size: 14px;
    min-width: fit-content;
}
@media (max-width: 850px) {
    .horizontal-song-stream-count {
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        width: fit-content;
    }
}
@media (max-width: 850px) {
    .horizontal-song-tile .top-song-tile-heart-pill{
        display: none;
    }
}

