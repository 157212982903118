.remix-screen {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--accent-background-color);
    color: var(--primary-text-color);
    max-height: 100vh;
    overflow-y: hidden;
}
.remix-screen .header {
    border-radius: 0;
    width: calc(100% - 50px);
}
@media (max-width: 850px) {
    .remix-screen {
        flex-direction: column;
        height: 100dvh;
        max-height: none;
    }
    .remix-screen .sidebar {
        display: none;
    }
    .remix-screen .header {
        width: calc(100% - 20px);
    }
}

.search-container {
    display: flex;
    height: calc(100vh - 200px);
    padding: 20px;
}
@media (max-width: 850px) {
    .search-container {
        padding: 0;
        height: calc(100% - 70px);
    }
}

.section {
    flex: 1;
    max-height: 100dvh;
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    padding: 0 20px;
}
@media (max-width: 850px) {
    .section {
        width: 80%;
        height: calc(100% - 60px);
        background-color: var(--accent-background-color);
    }
    .review-section-mobile {
        max-height: none;
    }
}

.section-step {
    margin-top: 20px;
    color: #b0b0b0;
    font-size: 0.9rem;
    padding-bottom: 5px;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--accent-background-color);
}
@media (max-width: 850px) {
    .section-step {
        position: relative;
    }
}

.section-title {
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 10px;
    margin: 0;
    align-self: start;
    position: sticky;
    top: 22px;
    z-index: 2;
    background-color: var(--accent-background-color);
    color: var(--primary-text-color);
}
@media (max-width: 850px) {
    .section-title {
        font-size: 1.2rem;
        position: relative !important;
        top: auto;
    }
}

.section-description {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #b0b0b0;
}
@media (max-width: 850px) {
    .section-description {
        font-size: 13px !important;
    }
}
.search-results {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 10px;
}
.step-nav-row {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    justify-content: flex-end;
    height: 60px;
    gap: 10px;
    width: 100%;
}
@media (max-width: 850px) {
    .step-nav-row {
        height: 60px;
        align-items: start;
    }
}
.next-step-button {
    border-radius: 5px;
    color: var(--primary-text-color);
    height: 40px;
    padding: 0 20px;
    width: fit-content;
    background-color: var(--background-color);
    border: 1px solid var(--soft-border-color);
    margin: 0;
    cursor: not-allowed;
}
@media (max-width: 850px) {
    .next-step-button {
        height: 40px;
    }
}
.next-step-button.ready {
    cursor: pointer;
    background-color: var(--light-action-button);
}
.next-step-button.active {
    cursor: pointer;
}
.next-step-button.disabled {
    cursor: not-allowed;
    background-color: var(--background-color);
    color: #b0b0b0;
}


.section-description {
    margin-bottom: 30px;
}



.selected-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
@media (max-width: 850px) {
    .selected-container {
        flex-direction: column;
    }
}

.selected-items {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
    gap: 5px;
    margin-right: 10px;
}
.selected-items h1 {
    font-size: 40px;
    font-weight: bold;
    color: var(--primary-text-color);
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
}
@media (max-width: 850px) {
    .selected-items {
        width: calc(100% - 20px);
        margin-right: 0;
    }
}

.selected-song, .selected-model {
    width: calc(100% - 20px);
    text-align: left;
    background-color: var(--background-color);
    border: 1px solid var(--soft-border-color);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.selected-song img, .selected-model img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
}

.selected-song p, .selected-model p {
    margin: 5px 0;
    color: var(--primary-text-color);
}

.remix-button {
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #ff4500;
    font-weight: bold;
    width: 100%;
    justify-content: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
    margin-bottom: 10px;
}
.remix-button:hover {
    background-color: white;
    color: #ff4500;
}

.remix-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}
.magicIcon, .spinnerIcon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.inferring-text {
    margin: 0;
    color: #b0b0b0;
}

.voice-reverb-title {
    margin: 10px 0 0 0;
    font-size: 18px;
    color: var(--primary-text-color);
}
.output-preview-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 55%;
    margin-right: 20px;
}
@media (max-width: 850px) {
    .output-preview-container {
        width: 100%;
        margin-top: 20px;
        overflow-x: hidden;
    }
    .output-preview-container .player-time-controls {
        display: flex !important;
    }
    .output-preview-container .form-text-entry {
        width: 90%;
    }
}

.output-preview-container h1 {
    margin: 0;
}
.output-preview-container h5 {
    margin: 0 0 10px 0;
    color: var(--secondary-text-color);
}
.song-details-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}
@media (max-width: 850px) {
    .song-details-container {
        flex-direction: column;
        align-items: center;
    }
}
.preview-cover {
    width: 30%;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    cursor: pointer;
}
.song-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}
@media (max-width: 850px) {
    .song-details {
        width: 100%;
        align-items: center;
    }
}

.song-preview-name {
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-text-color);
    align-self: start;
    margin: 0 0 0 5px;
    cursor: pointer;
    border: 1px solid var(--soft-border-color);
    padding: 2px 5px;
}

.song-preview-artist {
    font-size: 16px;
    align-self: start;
    color: var(--secondary-text-color);
    margin: 0 0 0 5px;
    cursor: pointer;
    padding: 2px 5px;
    border: 1px solid var(--soft-border-color);
}
@media (max-width: 850px) {
    .song-preview-name, .song-preview-artist {
        text-align: center;
        width: calc(100% - 22px);
    }
}
.preview-play-button {
    height: 40px;
    width: 40px;
    margin: 0 auto;
    cursor: pointer;
}
.song-details .player-time-controls {
    margin-top: -10px;
    margin-bottom: 10px;
}
.editable-title {
    border: 1px solid var(--soft-border-color);
}
