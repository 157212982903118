.login-screen {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
    width: 100vw;
    padding: 40px;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #0D7D4B;
}

/* headers */
.login-screen p{
    color: var(--background-color);
    font-weight: bold;
    font-size: 2vw;
}
.login-screen h1 {
    font-weight: normal;
    font-kerning: normal;
    font-size: 5vw;
    color: var(--background-color);
}
.login-screen a {
    color: #afafaf;
    text-decoration: none;
    font-size: 2.5vw;
}

/* form */
.login-form{
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
}
.login-text-box{
    width: 50vw;
    padding: 20px 10px;
    display: inline-block;
    border: none;
    box-sizing: border-box;
    font-size: 16px;
    color: var(--primary-text-color);
    background-color: var(--background-color);
    caret-color: var(--primary-text-color);
}
.login-text-box::placeholder{
    color: var(--primary-text-color);
}
.login-text-box:focus{
    border: none;
    outline: none;
}

.login-button{
    transition: 0.3s;
    background-color: #d2f882;
    color: var(--primary-text-color);
    border: none;
    font-weight: bold;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
}

.login-button:hover {
    cursor: pointer;
    transition: 0.2s;
    background-color: #c9c9c9;
}


.no-account-text{
    font-family: 'Inter', sans-serif;

    text-align: center;
    font-weight: 100;
    font-size: 15px;
    text-decoration: none;
    color: var(--text-color);
}

.no-account-text:hover {
    color: var(--text-color);
}
