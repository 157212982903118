.search-result-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid var(--soft-border-color);
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s, border 0.3s;
  background-color: var(--background-color);
  color: white;
  height: 70px;
}

.search-result-item:hover {
  border: 1px solid var(--primary-text-color);
}

.search-result-item.selected {
  background-color: var(--active-button);
  border: 1px solid var(--primary-text-color);
}

.image-container {
  position: relative;
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.item-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.item-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.item-name {
  margin: 0;
  color: var(--primary-text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-creator {
  margin: 0;
  color: var(--secondary-text-color) !important;
}

.item-stats {
  margin-top: 5px;
  color: var(--primary-text-color);
  display: flex;
  align-items: center;
}

.item-stats p {
  margin: 0 10px 0 0;
  color: var(--primary-text-color);
}

.item-stats .streamCountIcon {
  width: 18px;
  height: 18px;
}

.item-stats .favoriteCountIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.item-stats .starFilledIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
