:root {
    --background-color: rgb(0, 0, 0);
    --fun-object-background: rgba(45, 58, 134, 0.65);
    --accent-background-color: #111;
    --object-background-color: #212121;
    --primary-text-color: rgb(255,255,255);
    --secondary-text-color: rgb(157, 157, 157);
    --active-button: #595959;
    --intermediary-background-color: rgba(33, 33, 33, 0.7);
    --strong-intermediary-background-color: rgba(44, 43, 43, 0.9);
    --soft-border-color: #4f4f4f;
    --light-action-button: #4a5575;
    --shadow-color: rgba(0, 0, 0, 1);
}

html {
    background-color: var(--background-color);
}

/*--background-color: #F8F8F8;*/
/*--object-background-color: #f5efdd;*/
/*--text-color: #000000;*/
/*--action-button: #73baff;*/
/*--activated-action-button: #0056B3;*/
/*--soft-action-button-hover: #D0E2FF;*/
/*
:root {
    --background-color: #eee7ff;
    --background-hover-color: #efe9ff;
    --lighter-background-color: #fff;
    --text-color: #000;
    --action-button: #bda6ff;
    --darker-action-button: #af98e7;
    --active-action-button: #956bff;
    --translucent-button: rgba(246, 246, 246, 0.53);
    --translucent-button-hover: rgba(246, 246, 246, 0.32);
}


[data-theme="dark"] {
    --background-color: #0c0032;
    --background-hover-color: #0f0044;
    --lighter-background-color: #190061;
    --text-color: #ffffff;
    --action-button: #3500d3;
    --darker-action-button: #240090;
    --active-action-button: #af98e7;
    --translucent-button: rgba(40, 40, 40, 0.53);
    --translucent-button-hover: rgba(40, 40, 40, 0.32);
}
*/
