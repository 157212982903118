.top-song-tile {
    width: 240px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: flex-start;
    min-width: 240px;
}
@media (max-width: 850px) {
    .top-song-tile {
        max-width: 160px;
        min-width: 190px;
    }
}

/* top song tile info */
.top-song-info{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    padding-bottom: 4px;
    border-bottom: 0.5px solid var(--soft-border-color);
    margin-bottom: 5px;
}
.top-song-rank {
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0  10px 0 0;
}
.top-song-rank p {
    margin: 0;
    font-size: 35px;
    font-weight: bold;
    color: var(--primary-text-color);
}
@media (max-width: 850px) {
    .top-song-rank p {
        font-size: 25px;
    }
}

.top-song-title {
    margin: 0;
    color: var(--primary-text-color);
    font-size: 15px;
    font-weight: normal;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.top-song-title:hover {
    cursor: pointer;
    text-decoration: underline;
}
@media (max-width: 850px) {
    .top-song-title {
        -webkit-line-clamp: 1 !important;
        font-size: 15px;
    }
}
.top-song-artist {
    margin: 0;
    color: var(--secondary-text-color);
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    font-size: 15px;
    font-weight: normal;
}
.top-song-artist:hover {
    cursor: pointer;
    text-decoration: underline;
}
@media (max-width: 850px) {
    .top-song-artist {
        font-size: 15px;
    }
}

.top-song-stats {
    height: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.top-song-stream-count {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    font-size: 15px;
    color: var(--secondary-text-color);
    margin: 0;
    cursor: pointer;
}
@media (max-width: 850px) {
    .top-song-stream-count {
        font-size: 12px;
    }
}
.top-song-tile-heart-pill {
    height: 25px;
    justify-content: center;
    border-radius: 5px;
    gap: 7px;
    align-items: center;
    display: flex;
    flex-direction: row;
}
.top-song-tile-heart-pill:hover {
    cursor: pointer;
}
.top-song-tile-heart-pill p {
    margin: 0;
    font-weight: bold;
    color: var(--primary-text-color);
    font-size: 12px;
}
.top-song-tile-heart {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-song-cover {
    width: 100%;
    aspect-ratio: 1;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    margin-top: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.top-song-play-button {
    background-color: #FF4E4E;
    border-radius: 50%;
    display: flex;
    justify-self: center;
    align-self: center;
    cursor: pointer;
    padding: 7.5px;
    /*margin: 5px 5px 5px auto;*/
}
@media (max-width: 850px) {
    .top-song-play-button {
        padding: 5px;
        margin: 0;
    }
}
.top-song-play-button img {
    filter: invert(1);
    height: 25px;
    width: 25px;
}
@media (max-width: 850px) {
    .top-song-play-button img {
        height: 20px;
        width: 20px;
    }
}

.top-song-tile:hover .top-song-remix-overlay-container {
    display: flex;
}
.top-song-remix-overlay-container {
    position: absolute;
    display: none;
    bottom: 3px;
    left: 3px;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    transition: opacity 0.3s;
    padding: 10px 10px;
    gap: 3px;
}
.top-song-remix-overlay-container:hover {
    cursor: pointer;
}
.top-song-remix-overlay-container p {
    color: white;
    font-size: 13px;
    margin: 0;
}

