.upload-password-page {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: var(--background-color);
}
@media (max-width: 850px) {
    .upload-password-page {
        width: 100%;
        height: 100dvh;
    }
}
.upload-password-page h1 {
    color: var(--primary-text-color);
}
@media (max-width: 850px) {
    .upload-password-page h1 {
        font-size: 6vw;
    }
}
.upload-password-page input {
    padding: 5px;
    border-radius: 5px;
    height: 30px;
    width: 400px;
    color: var(--primary-text-color);
    border: none;
    background-color: var(--accent-background-color);
}
@media (max-width: 850px) {
    .upload-password-page input {
        width: 80%;
    }
}
.upload-password-page input:focus {
    outline: none;
}
.upload-password-page button {
    padding: 10px;
    border-radius: 5px;
    height: 30px;
    width: 410px;
    background-color: var(--light-action-button);
    display: flex;
    justify-content: center;
    border: none;
    align-items: center;
    color: var(--primary-text-color);
}
@media (max-width: 850px) {
    .upload-password-page button {
        width: 90%;
    }
}
.upload-password-page p {
    color: var(--light-action-button);
    filter: brightness(0.8);
    text-decoration: underline;
}
.upload-password-page p:hover {
    cursor: pointer;
}
.upload-password-page button:hover {
    cursor: pointer;
}

.upload-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: var(--background-color);
}
@media (max-width: 850px) {
    .upload-page {
        flex-direction: column;
    }
}

/* selector section */
.upload-page-selector-section {
    width: 350px;
    margin: 20px 20px 20px 20px;
    border-radius: 10px;
    padding: 20px;
    height: calc(100vh - 82px);
    overflow-y: scroll;
    scrollbar-width: none;
    background-color: var(--accent-background-color);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 15px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

@media (max-width: 850px) {
    .upload-page-selector-section {
        width: calc(100% - 40px);
        position: relative;
        height: auto;
        padding: 20px;
    }
}

/* sidebar */
.upload-header {
    height: 60px;
    width: 100%;
}
@media (max-width: 850px) {
    .upload-header {
        height: 30px;
    }
}

.upload-header button {
    background-color: rgba(0,0,0,0);
    border: none;
    padding: 15px 0;
    color: var(--primary-text-color);
    transition: 0.2s;
    font-size: 18px;
}
.upload-header button:hover {
    color: var(--primary-text-color);
    cursor: pointer;
}
@media (max-width: 850px) {
    .upload-header button {
        padding: 10px 0;
        font-size: 3vw;
    }
}
.upload-title {
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: bold;
    font-kerning: normal;
    color: var(--primary-text-color);
}
.model-origin-subtitle {
    font-size: 15px;
    font-weight: normal;
    color: var(--secondary-text-color);
}
.model-count-title {
    font-size: 15px;
    font-weight: normal;
    margin-top: 20px !important;
    color: var(--primary-text-color);
}
.model-counts-container {
    height: 30px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.model-count-selector {
    padding: 0 30px;
    height: 30px;
    background-color: var(--accent-background-color);
    border: 1px solid var(--soft-border-color);
    border-radius: 5px;
    color: var(--primary-text-color);
    cursor: pointer;
}
.active-model-count {
    border: 1px solid var(--primary-text-color);
    background-color: var(--background-color);
}
.model-identifier {
    font-size: 15px;
    margin-top: 20px;
    font-weight: normal;
    color: var(--secondary-text-color);
}
@media (max-width: 850px) {
    .upload-title {
        margin-top: 15px;
        font-size: 6vw;
    }
}

.upload-details {
    font-size: 15px;
    font-weight: normal;
    color: var(--secondary-text-color);
}
/* upload options */
.upload-options-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
@media (max-width: 850px) {
    .upload-options-container {
        display: none;
    }
}

.upload-options-container p{
    margin: 0 0 10px 0;
    font-weight: bold;
    font-size: 15px;
    color: var(--primary-text-color);
}
.upload-option-container {
    height: 50px;
    border: 2px solid var(--soft-border-color);
    border-radius: 10px;
    overflow: hidden;
    width: calc(100% - 45px);
    background-color: var(--accent-background-color);
    padding: 15px 30px 15px 15px;
    transition: 0.2s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.upload-option-container:hover {
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    cursor: pointer;
}
.upload-option-container p {
    font-weight: normal;
    color: var(--primary-text-color);
    font-size: 15px;
    margin: 0;
}
.upload-option-container img {
    height: 50%;
}
.active-upload-type {
    background-color: var(--background-color);
    border: 2px solid var(--primary-text-color);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
}
.active-upload-type:hover {
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
}

/* form section */
.upload-page-form-section {
    flex: 1;
    height: 100vh;
    background-color: var(--background-color);
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
@media (max-width: 850px) {
    .upload-page-form-section {
        margin-left: 0;
        width: 100%;
        height: auto;
    }
}

/* upload model form */
.upload-form {
    padding: 20px;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}
/* form subsections */
.upload-form-section {
    flex: 1;
    margin-right: 20px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px;
    justify-content: flex-start;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 15px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-color: var(--accent-background-color);
}

.upload-form-section h1 {
    font-weight: bold;
    margin: 0;
    color: var(--secondary-text-color);
    font-size: 20px;
}
.upload-form-section h2 {
    font-weight: normal;
    margin: 0 0 20px 0;
    color: var(--primary-text-color);
    font-size: 30px;
}
.upload-form-navigation-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: auto;
    margin-left: auto;
}
.upload-form-next-step-bttn, .upload-form-last-step-bttn {
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    color: var(--primary-text-color);
    border: 1px solid var(--soft-border-color);
    transition: 0.2s;
}
.upload-form-next-step-bttn {
    background-color: var(--light-action-button);
}
.upload-form-last-step-bttn {
    background-color: var(--active-button);
}
.upload-form-next-step-bttn:hover, .upload-form-last-step-bttn:hover {
    cursor: pointer;
}

.form-entry-title {
    font-size: 15px;
    font-weight: normal;
    margin: 0 0 10px 0;
    color: var(--primary-text-color);
}
.form-entry-description {
    font-size: 13px;
    font-weight: normal;
    margin: -10px 0 20px 0;
    color: var(--secondary-text-color);
}
.tick-details {
    font-size: 14px;
    color: var(--primary-text-color);
    margin-bottom: 20px;
    margin-top: 10px;
    gap: 10px;
    display: flex;
    align-items: center;
}
.custom-popover {
    padding: 10px !important;
    background-color: var(--accent-background-color) !important;
    border: none;
    border-radius: 5px !important;
    color: var(--secondary-text-color) !important;
    font-size: 11px !important;
}
.form-text-entry {
    width: 500px;
    height: 30px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--soft-border-color);
    background-color: var(--accent-background-color);
    color: var(--primary-text-color);
    margin-bottom: 15px;
    pointer-events: auto;
}
.form-text-entry:focus {
    outline: none;
    border: 1px solid var(--primary-text-color);
}
.form-text-entry::placeholder {
    font-style: italic;
}

.form-file-entry {
    pointer-events: auto;
    display: block;
    width: 500px;
    min-width: 500px;
    height: 30px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--soft-border-color);
    background-color: var(--accent-background-color);
    color: var(--primary-text-color);
    margin-bottom: 15px;
    transition: 0.2s;
}

.form-file-entry:hover {
    outline: none;
    cursor: pointer;
    border: 1px solid var(--primary-text-color);
}
.image-drop-area {
    height: 200px;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    border: 2px dotted var(--soft-border-color);
}
.image-drop-area label {
    font-size: 1.5vw;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #575757
}
.image-drop-area label:hover {
    cursor: pointer;
}
.image-drop-area:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
}
.image-drop-message{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.image-drop-area input {
    height: 200px;
    width: 200px;
    opacity: 0;
    position: absolute;
    z-index: 1;
}
.image-drop-area input:hover {
    cursor: pointer;
}
.photo-input {
    width: 100%;
    text-align: center;
}
.preview-image {
    height: 200px;
    width: 200px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}
.submit-form {
    padding: 10px 20px;
    background-color: var(--light-action-button);
    border-radius: 5px;
    font-weight: bold;
    color: var(--primary-text-color);
    border: 1px solid var(--soft-border-color);
    transition: 0.2s;
}
.error-message {
    color: var(--primary-text-color);
    font-size: 14px;
    margin: 0;
}

.submit-form:hover {
    cursor: pointer;
}
.submit-form:disabled {
    background-color: var(--soft-border-color);
    color: var(--secondary-text-color);
    cursor: not-allowed;
}
.model-method-buttons {
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.model-method-button {
    width: 300px;
    padding: 10px 20px;
    height: 80px;
    border: 1px solid var(--soft-border-color);
    background-color: var(--active-button);
    color: var(--primary-text-color);
    border-radius: 7px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    font-size: 15px;
}
.model-method-button:hover {
    cursor: pointer;
}
.active-model-source {
    background-color: var(--light-action-button);
    border: 1px solid var(--primary-text-color);
}

.existing-model {
    width: 470px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--soft-border-color);
    padding: 10px 20px;
    background-color: var(--background-color);
    border-radius: 10px;
}
.existing-model:hover {
    cursor: pointer;
}
.existing-model p {
    margin: 3px;
    color: var(--primary-text-color);
}
.existing-model-stats p{
    text-align: right;
}
.existing-model-creator, .existing-model-likes {
    color: var(--secondary-text-color) !important;
}
.selected-existing-model {
    background-color: var(--light-action-button);
    border: 1px solid var(--primary-text-color);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
}
.upload-status-overlay {
    background-color: var(--background-color);
    height: calc(80% - 60px);
    display: flex;
    flex-direction: column;
    padding: 30px;
    position: absolute;
    z-index: 2;
    width: calc(50% - 60px);
    border: 1px solid var(--soft-border-color);
    border-radius: 10px;
    top: 10%;
    left: 25%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.upload-status-overlay p, .upload-status-overlay h1 {
    margin: 0;
    font-weight: normal;
}
.upload-status-overlay h1 {
    color: var(--primary-text-color);
}
.warning {
    color: var(--secondary-text-color)
}
.status-container {
    width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 25px;
}
.status-container p {
    color: var(--secondary-text-color);
    font-size: 14px;
}
.status-container h1 {
    color: var(--primary-text-color);
    font-size: 19px;
    font-weight: normal;
}
.status-container div {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.status-container div p {
    color: var(--secondary-text-color);
    font-size: 18px;
    font-weight: normal;
}
.status-container img{
    height: 30px;
}
.go-back-button {
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    border: none;
}
.go-back-button:hover {
    cursor: pointer;
}

.go-back-button.disabled {
    opacity: 0.5; /* makes the button appear greyed out */
    cursor: not-allowed; /* changes the cursor to indicate the button is not clickable */
}
.next-step-button.disabled {
    opacity: 0.5; /* makes the button appear greyed out */
    cursor: not-allowed; /* changes the cursor to indicate the button is not clickable */
}



