.top-songs-screen {
    background-color: var(--background-color);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    width: 100%;
}
@media (max-width: 850px) {
    .top-songs-screen {
        flex-direction: column;
        height: fit-content;
    }
}

.top-songs-content {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.back-button {
    margin: 0;
    color: var(--secondary-text-color);
    padding: 20px 10px 10px 20px;
    cursor: pointer;
}
@media (max-width: 850px) {
    .back-button {
        font-size: 13px;
    }
}

.top-songs-screen-title {
    margin: 20px 0 0 20px;
    font-size: 30px;
    color: var(--primary-text-color);
}
@media (max-width: 850px) {
    .top-songs-screen-title {
        font-size: 20px;
        margin: 10px 0 0 20px;
    }
}
.top-songs-sort-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px 0 0 20px;
}
.top-songs-sort-container button {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    background-color: var(--accent-background-color);
    color: var(--primary-text-color);
    border: 1px solid var(--soft-border-color);
    border-radius: 5px;
}
.top-songs-sort-container button:hover {
    cursor: pointer;
}
.active-sort-button {
    background-color: var(--active-button) !important;
    border: 1px solid var(--primary-text-color) !important;
}

.songs-container {
    width: calc(100% - 60px);
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 20px;
    gap: 10px;
    overflow-y: auto;
    margin-top: 20px;
    flex-wrap: wrap;
}
@media (max-width: 850px) {
    .songs-container {
        flex-direction: row;
        align-items: center;
        width: calc(100% - 30px);
        gap: 5px;
    }
}
