/* song */
.home-song-tile {
    width: 100%;
    margin-left: 10px;
    padding: 10px 10px 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
@media (max-width: 850px) {
    .home-song-tile {
        height: 130px;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        border-bottom-left-radius: 0;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        padding: 0;
        margin-left: 0;
        width: 100%;
    }
}

/* song cover */
.home-song-tile-cover{
    height: 100%;
    aspect-ratio: 1;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.home-song-tile-cover:hover{
    cursor: pointer;
}
@media (max-width: 850px) {
    .home-song-tile-cover{
        box-shadow: none;
        height: 90%;
        aspect-ratio: 1;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }
}
.home-song-tile-cover:hover .top-song-remix-overlay-container {
    display: flex;
}


/* play button */
.high-five-play-button {
    height: 50px;
    background-color: #FF4E4E;
    border-radius: 50%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
}
@media (max-width: 850px) {
    .high-five-play-button {
        height: 50px !important;
    }
}
.high-five-play-button img {
    width: 70%;
    height: 70%;
    filter: invert(100);
    transition: 0.2s;
}
@media (max-width: 850px) {
    .high-five-play-button img {
        width: 70%;
        height: 70%;
    }
}

/* mobile visibility */
@media (max-width: 850px) {
    .high-five-song-info-desktop {
        display: none;
    }
    .desktop-stream-count {
        display: none;
    }
}
@media (min-width: 850px) {
    .high-five-song-info-mobile {
        display: none;
    }
}

.home-song-tile-song-info {
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}
@media (max-width: 850px) {
    .home-song-tile-song-info {
        height: calc(100% - 10px);
    }
}
.home-tile-top-info {
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.home-song-tile-title {
    font-weight: normal;
    font-size: 15px;
    color: var(--primary-text-color);
    margin: 0;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.home-song-tile-title:hover {
    cursor: pointer;
    text-decoration: underline;
}

.home-song-tile-model-title {
    font-weight: normal;
    font-size: 12px;
    color: var(--secondary-text-color);
    margin: 0;
    -webkit-line-clamp: 2;
    text-decoration: underline;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (max-width: 850px) {
    .home-song-tile-model-title {
        margin: 0 !important;
    }
}
.home-song-tile-artist {
    font-weight: normal;
    font-size: 12px;
    color: var(--secondary-text-color);
    margin: 0;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.home-song-tile-artist:hover {
    cursor: pointer;
}

.home-song-tile-actions-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.home-song-tile-heart-pill {
    border-radius: 5px;
    align-items: center;
    display: flex;
    gap: 5px;
    flex-direction: row;
    /*background-color: var(--background-color);*/
}
.home-song-tile-heart-pill:hover {
    cursor: pointer;
}

.home-song-tile-heart-pill p {
    margin: 0;
    color: var(--primary-text-color);
    font-size: 12px;
}

.home-song-tile-heart {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-song-tile-model-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.home-song-tile-model-info:hover {
    cursor: pointer;
}
.home-song-tile-model-info:hover .home-song-tile-model-title {
    text-decoration: underline;
}
.home-tile-model-left-info {
    height: 100%;
    display: flex;
    flex-direction: column;
}
@media (max-width: 850px) {
    .home-tile-model-left-info {
        align-items: center;
        flex-direction: row;
        gap: 5px;
    }
}

.home-tile-model-left-info p{
    margin-left: 5px;
}
.home-tile-score-container {
    display: flex;
    flex-direction: row;
    padding: 3px 0;
    gap: 5px;
    align-items: center;
    /*background-color: var(--accent-background-color);*/
    border-radius: 20px;
    width: fit-content;
}
.home-tile-score-container img {
    height: 13px;
    width: 13px;
}
@media (max-width: 850px) {
    .home-tile-score-container img {
        height: 12px;
        width: 12px;
    }
}
.home-tile-score {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: var(--secondary-text-color);
}
@media (max-width: 850px) {
    .home-tile-score {
        font-size: 14px;
    }
}

.home-tile-model-right-info button {
    color: var(--secondary-text-color);
    height: 25px;
    width: 25px;
    font-weight: bold;
    background-color: transparent;
    font-size: 18px;
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    border-radius: 12.5px;
    border: 1px solid var(--secondary-text-color);
}
@media (max-width: 850px) {
    .home-tile-model-right-info button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px !important;
        height: 25px;
        font-size: 12px;

        aspect-ratio: 1 !important;
    }
}
.home-tile-model-right-info button:hover {
    cursor: pointer;
}
@media (max-width: 850px) {
    .home-tile-model-right-info button{

    }
}

.not-rated {
    margin: 0;
    font-size: 13px;
    color: var(--secondary-text-color);
}
.rating-star {
    height: 15px;
}

.home-song-tile-reverse-info {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home-tile-model-actions-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 40px;
}
.home-tile-model-actions-left {
    flex: 1;
    display: flex;
    gap: 10px;
}
.home-tile-model-actions-left button{
    border-radius: 10px;
    border: 1px solid var(--soft-border-color);
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}
.home-tile-model-actions-left button:hover{
    cursor: pointer;
}
.download-image {
    width: 28px;
    height: 28px;
}
.heart-image {
    width: 20px;
    height: 20px;
}
