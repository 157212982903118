.horizontal-model-tile {
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid var(--soft-border-color);
}
.horizontal-model-rank{
    font-size: 20px;
    margin-right: 20px;
    color: var(--secondary-text-color);
}
@media (max-width: 850px) {
    .horizontal-model-rank {
        font-size: 15px;
        margin-right: 10px;
    }
}
.horizontal-model-cover {
    height: 90%;
    border-radius: 6px;
    aspect-ratio: 1;
    background-size: cover;
    background-position: center;
    align-items: center;
    justify-content: center;
    display: flex;
}
.horizontal-model-info {
    flex: 1;
}
.horizontal-model-title {
    font-size: 15px;
    color: var(--primary-text-color);
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    margin: 0;
    font-weight: bold;
    cursor: pointer;
}
@media (max-width: 850px) {
    .horizontal-model-title {
        font-size: 12px;
        word-spacing: -2px;
        letter-spacing: -1px;
    }
}
.horizontal-model-title:hover {
    text-decoration: underline;
}
.horizontal-model-creator {
    font-size: 14px;
    color: var(--secondary-text-color);
    margin: 0;
}
@media (max-width: 850px) {
    .horizontal-model-creator {
        font-size: 12px;
        word-spacing: -2px;
        letter-spacing: -1px;
    }
}
.horizontal-model-creator:hover {
    text-decoration: underline;
}
.horizontal-model-stats {
    display: flex;
    flex-direction: row;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}
.horizontal-model-download-count {
    margin: 0 10px;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: var(--primary-text-color);
    font-weight: bold;
    font-size: 14px;
    min-width: fit-content;
}
@media (max-width: 850px) {
    .horizontal-model-download-count {
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        width: fit-content;
    }
}
@media (max-width: 850px) {
    .horizontal-model-tile .top-song-tile-heart-pill{
        display: none;
    }
}

