/* top songs container*/
.top-songs {
    width: calc(100% - 140px);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: rgba(54, 30, 36, 0.8);
    padding: 30px;
}
@media screen and (max-width: 850px) {
    .top-songs {
        width: calc(100% - 50px);
        padding: 0 15px 15px 15px;
    }
}

.top-songs-title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
@media screen and (max-width: 850px) {
    .top-songs-title-row {
        margin-bottom: 0;
    }
}
.top-songs-title {
    margin: 0;
    font-size: 22px;
    color: var(--secondary-text-color);
    font-weight: bold;
}
@media screen and (max-width: 850px) {
    .top-songs-title {
        font-size: 19px;
        margin: 25px 0 10px 0;
        word-spacing: -2px;
        letter-spacing: -1px;
    }
}
.see-all {
    cursor: pointer;
}
.see-all:hover {
    text-decoration: underline;
}
.top-songs-title-row p {
    margin: 0;
    font-size: 15px;
    color: var(--secondary-text-color);
}
.top-songs-carousel {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;
    overflow-x: scroll;
    scrollbar-width: none;
}
@media screen and (max-width: 850px) {
    .top-songs-carousel {
        gap: 22px;
    }
}
.top-songs-list {
    margin-top: 10px;
}

.top-songs-view-all {
    width: 100%;
    text-align: center;
    margin: 5px 0 0 0;
    color: var(--primary-text-color);
    font-size: 14px;
    font-weight: bold;
}
.top-songs-view-all:hover {
    cursor: pointer;
    text-decoration: underline;
}
