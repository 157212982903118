.top-model-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding-bottom: 7px;
}
.top-model-container p {
    margin: 0;
}
.top-model-first-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    padding-bottom: 5px;
}
.top-model-place {
    font-weight: bold;
    color: var(--primary-text-color);
    font-size: 40px;
    padding-right: 10px;
}
.top-model-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.top-model-title {
    font-weight: bold;
    -webkit-line-clamp: 1;
    font-size: 20px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    color: var(--primary-text-color);
    cursor: pointer;
}

@media (max-width: 850px) {
    .top-model-title {
        font-size: 17px;
        word-spacing: -2px;
        letter-spacing: -1px;
    }
}
.top-model-title:hover {
    text-decoration: underline;
}
.top-model-creator {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    color: var(--secondary-text-color);
    cursor: pointer;
}
@media (max-width: 850px) {
    .top-model-creator {
        font-size: 15px;
    }
}
.top-model-creator:hover {
    text-decoration: underline;
}

.top-model-stats {
    height: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-bottom: 7px;
}

.top-model-score {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    font-weight: bold;
    -webkit-box-orient: vertical;
    font-size: 20px;
    color: var(--primary-text-color);
    margin: 0;
}
@media (max-width: 850px) {
    .top-model-score {
        font-size: 17px;
    }
}

.top-model-tile-heart-pill {
    height: 25px;
    justify-content: center;
    border-radius: 5px;
    gap: 7px;
    align-items: center;
    display: flex;
    flex-direction: row;
    border: 1px solid var(--soft-border-color);
    padding: 0 5px;
}
.top-model-tile-heart-pill:hover {
    cursor: pointer;
}
.top-model-tile-heart-pill p {
    margin: 0;
    font-weight: bold;
    color: var(--primary-text-color);
    font-size: 12px;
}
.top-model-tile-heart-pill img {
    height: 20px;
}

.top-model-creations-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 7px;
    gap: 10px;
    height: fit-content;
}
.top-model-song {
    flex: 1;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    height: 80px;
}
.top-model-song-cover {
    height: 100%;
    aspect-ratio: 1;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.top-model-song-cover .high-five-play-button {
    height: 30px;
    width: 30px;
    cursor: pointer;
}
@media (max-width: 850px) {
    .top-model-song-cover .high-five-play-button {
        height: 25px !important;
        width: 25px;
    }
}
.top-model-song-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}
.top-model-song-title {
    font-weight: bold;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    color: var(--primary-text-color);
    cursor: pointer;
}
.top-model-song-artist {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    color: var(--secondary-text-color);
    cursor: pointer;
}
.top-model-song-artist:hover, .top-model-song-title:hover {
    text-decoration: underline;
}
