/* sort options container */
.sort-options-container {
    border-radius: 10px;
    width: calc(100% - 80px);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
/* sort option container */
@media (max-width: 850px) {
    .sort-options-container {
        width: calc(100% - 20px);
        margin-top: 0px;
        flex-direction: column;
        align-items: flex-start;
    }
}
.sort-options-container h6 {
    font-size: 18px;
    margin: 0;
    font-weight: normal;
    color: var(--primary-text-color);
}
@media (max-width: 850px) {
    .sort-options-container h6 {
        font-size: 14px;
        margin: 5px 0;
    }
}
/* sort options buttons */
.sort-options-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
}
.sort-options-buttons button {
    background-color: var(--background-color);
    color: var(--primary-text-color);
    border: 1px solid var(--soft-border-color);
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 13px;
    transition: 0.2s;
}
.sort-options-container button:hover {
    cursor: pointer;
}
@media (max-width: 850px) {
    .sort-options-buttons button {
        padding: 8px 12px;
        font-size: 13px;
    }
}
.sort-options-container .active {
    color: var(--primary-text-color);
    background-color: var(--active-button);
}
