.header {
    width: calc(100% - 80px);
    margin: 0;
    height: 70px;
    border-radius: 10px;
    padding: 15px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #000;
    z-index: 1000000;
}
@media screen and (max-width: 850px) {
    .header {
        flex-direction: row;
        height: 6dvh;
        padding: 10px;
        width: calc(100vw - 40px);
        border-right: none;
    }
}

.left-wrapper {
    height: 100%;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.header-left-section {
    flex-direction: column;
    display: flex;
    width: fit-content;
}
.header-top-left-section {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.header-title {
    font-family: Futura, sans-serif;
    color: var(--primary-text-color);
    font-size: 25px;
    min-width: fit-content;
    margin: 0;
}
.header-title:hover {
    cursor: pointer;
}
@media screen and (max-width: 850px) {
    .header-title{
        font-size: 15px;
        width: fit-content;
    }
}
.hamburger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.hamburger-menu img {
    width: 30px;
    height: 30px;
    transition: transform 0.3s ease;
}

.hamburger-menu img.flip {
    transform: rotate(180deg);
}

.header-subtitle {
    font-size: 15px;
    color: var(--secondary-text-color);
    margin: 0;
    min-width: fit-content;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media screen and (max-width: 850px) {
    .header-subtitle {
        display: none;
    }
}

.header-right-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.search-bar-container {
    position: relative;
    display: flex;
    align-items: center;
}

.search-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
}

.home-search-bar {
    padding: 8px 16px;
    border-radius: 20px;
    border: 1px solid var(--soft-border-color);
    font-size: 13px;
    transition: all 0.2s ease-in-out;
    position: absolute;
    right: 0;
    width: 0;
    visibility: hidden;
    background-color: var(--object-background-color);
    margin: 0 40px;
    color: var(--primary-text-color);
}
.home-search-bar:focus {
    outline: none;
}
.home-search-bar::placeholder {
    font-style: italic;
}
@media (max-width: 850px) {
    .home-search-bar {
        font-size: 0.7rem;
        margin: 10px 0;
        width: calc(100% - 60px);
    }
}
.home-search-bar.expanded {
    width: 200px;
    visibility: visible;
}

@media screen and (max-width: 1200px) {
    .search-bar-container {
        display: none !important;
    }
}
.header-right-section img {
    height: 25px;
}
.header-account-button {
    height: 50px;
    display: flex;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
    border-radius: .25rem;
    margin-right: 10px;
    background-color: transparent;
    border: 1px solid var(--soft-border-color);
    color: var(--primary-text-color);
    cursor: pointer;
    gap: 10px;
}
.header-account-button img {
    height: 30px !important;
    width: 30px !important;
    border-radius: 50%;
}

/* CSS */
.header-upload-button {
    background-color: #cf245f;
    background-image: linear-gradient(to bottom right, #fcd34d, #ef4444, #ec4899);
    border: 0;
    border-radius: .25rem;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    padding: 0 20px;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.header-upload-button:hover {
    box-shadow: none;
}
@media screen and (max-width: 850px) {
    .header-upload-button {
        display: none;
    }
}

@media screen and (max-width: 850px) {
    .header-discord {
        display: none;
    }
}
