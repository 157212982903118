.song-tile {
    width: 130px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--background-color);
    flex: 0 0 auto;
    /*border: 1px solid var(--secondary-text-color);*/
}
.song-tile:hover {
    cursor: pointer;
}
@media (max-width: 850px) {
    .song-tile {
        width: 150px;
        height: calc(100% - 20px);
        padding: 10px;
    }
}

.song-tile-image-container {
    width: 100%;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
}
.song-tile-image-container:hover {
    cursor: pointer;
}

.song-tile-info-section {
    width: 100%;
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    gap: 10px;
}
.song-tile-info-section h6 {
    color: var(--primary-text-color);
    font-size: 16px;
    font-weight: normal;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-break: break-word;
    margin: 0;
}
.song-tile-info-section h6:hover {
    cursor: pointer;
    text-decoration: underline;
}
@media (max-width: 850px) {
    .song-tile-info-section h6 {
        font-size: 3vw;
        height: 8vw;
    }
}

.song-tile-info-section p {
    color: #b4b4b4;
    font-size: 14px;
    font-weight: normal;
    --webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-break: break-word;
}
@media (max-width: 850px) {
    .song-tile-info-section p {
        font-size: 2.5vw;
    }
}
