.model-list {
    flex-direction: row;
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    width: 95%;
    gap: 1.5vw;
    overflow-x: auto;
    align-items: flex-start;
    scrollbar-width: none;
    overflow-y: hidden;
    margin-bottom: 30px;
}

@media (max-width: 850px) {
    .model-list {
        width: 90%;
        gap: 0;
        scrollbar-width: thin;
        height: 250px;
    }
}
