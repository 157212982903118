.header-overlay {
    width: calc(100vw - 60px) !important;
    margin: 0;
    padding: 0 30px 25px 30px;
    position: fixed;
    top: 100px;
    left: 0;
    z-index: 100;
    background-color: var(--background-color);
    transform: translateY(-200%);
    transition: transform 0.3s ease-in-out;
    border-bottom: 1px solid var(--soft-border-color);
}
@media (max-width: 850px) {
    .header-overlay {
        top: 59px;
        width: calc(100vw - 20px) !important;
        padding: 0 10px 10px 10px;
    }
}
.header-overlay.visible {
    transform: translateY(0);
}

.header-overlay-row {
    background-color: var(--background-color);
    width: calc(100% - 20px);
    padding: 0 15px;
    align-items: center;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header-overlay-row button {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 40px;
    color: var(--primary-text-color);
    background-color: transparent;
    border: none;
}

.header-overlay-row h3 {
    text-decoration: underline;
    color: var(--primary-text-color);
    margin: 0;
}

.header-overlay-row p {
    margin: 12px 0 0 0;
    color: var(--secondary-text-color);
    font-size: 17px;
}
.header-overlay-row p:hover {
    font-weight: bold;
    color: var(--primary-text-color);
    cursor: pointer;
}
