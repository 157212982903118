.top-creators {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;
    background-color: rgba(43, 30, 54, 0.8);
    border-radius: 10px;
}
@media screen and (max-width: 850px) {
    .top-creators {
        padding: 0 20px 20px 20px;
    }
}

.top-creators-title {
    margin: 0;
    font-size: 22px;
    color: var(--secondary-text-color);
    font-weight: bold;
}

@media screen and (max-width: 850px) {
    .top-creators-title {
        font-size: 19px;
        margin: 25px 0 10px 0;
        word-spacing: -2px;
        letter-spacing: -1px;
    }
}

.top-creator {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100px;
    margin-top: 5px;
}
.top-creator-rank {
    font-size: 25px;
    color: var(--secondary-text-color);
    height: 100%;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: center;
    width: 30px;
    align-items: center;
    margin: 0 10px 0 0;
}
.runner-up-creator {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 80px;
    margin-top: 15px;
}
.runner-up-creator .top-creator-rank {
    font-weight: normal;
    font-size: 15px;
}
.top-creator-pfp {
    height: calc(100% - 6px);
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 2px;
    border: 1px solid var(--primary-text-color);
}
.runner-up-creator .top-creator-pfp {
    padding: 0;
    height: 100%;
    margin-left: 10px;
    border: none;
}

.top-creator-info {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    margin-left: 10px;
}
.runner-up-creator .top-creator-info {
    /*margin-left: 20px;*/
}
.top-creator-info p {
    margin: 0;
}
.top-creator-name {
    font-size: 20px;
    color: var(--primary-text-color);
    font-weight: bold;
    cursor: pointer;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
@media screen and (max-width: 850px) {
    .top-creator-name {
        -webkit-line-clamp: 2;
    }
}
.runner-up-creator .top-creator-name {
    font-size: 15px;
}
@media screen and (max-width: 850px) {
    .top-creator-name {
        word-spacing: -2px;
        letter-spacing: -1px;
    }
}
.top-creator-streams {
    font-size: 15px;
    color: var(--secondary-text-color);
}
@media screen and (max-width: 850px) {
    .top-creator-streams {
        font-size: 12px;
    }
}
