/* screen */
.home-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    overflow: hidden;
    position: relative;
    background-color: var(--background-color);
}
@media (max-width: 850px) {
    .home-screen {
        overflow-y: scroll;
        height: 100dvh;
        flex-direction: column;
    }
}

/* content w/ ref */
.home-content-with-ref {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    background-color: var(--background-color);
    position: relative;
}
@media (max-width: 850px) {
    .home-content-with-ref {
        width: 100%;
        margin: 0;
    }
}
@media (min-width: 850px) {
    .mobile-sort-options {
        display: none;
    }
}

.top-models-top-creators-row {
    display: flex;
    flex-direction: row;
    width: calc(100% - 80px);
    gap: 20px;
    margin-top: 20px;
}
@media (max-width: 850px) {
    .top-models-top-creators-row {
        flex-direction: column;
        width: calc(100% - 20px);
        margin-top: 10px;
    }
}
.home-screen-section-title {
    width: calc(100% - 80px);
    margin-top: 40px;
    font-size: 22px;
    color: var(--primary-text-color);
}
@media (max-width: 850px) {
    .home-screen-section-title {
        width: calc(100% - 40px);
        font-size: 19px;
        margin: 30px 0 10px 0;
    }
}

/* feed container */
.feed-container {
    width: calc(100% - 80px);
    display: flex;
    flex-direction: row;
    padding-bottom: 100px;
    gap: 10px;
    flex-wrap: wrap;
}
@media (max-width: 850px) {
    .feed-container {
        width: calc(100% - 40px);
        margin-left: 0;
    }
}

.home-song-tile-wrapper {
    width: 32%;
    margin: 0;
    height: 170px;
    min-width: 315px;
    flex: 1;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
}

@media (max-width: 1200px) {
    .home-song-tile-wrapper {
        flex: none;
        width: 100%;
        min-width: 0;
    }
}
@media (max-width: 850px) {
    .home-song-tile-wrapper {
        flex-direction: column;
        height: auto;
    }
}
