.model-screen {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
    background-color: var(--background-color);
}
@media (max-width: 850px) {
    .model-screen {
        height: 100dvh;
        width: 100vw;
        flex-direction: column;
        overflow-y: scroll;
        scrollbar-width: none;
        min-height: 100dvh;
    }
}

.model-screen-artist-details {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to left, transparent, var(--intermediary-background-color), var(--accent-background-color)), url('../../assets/images/banner.jpeg') no-repeat right bottom;
    background-size: cover;
    padding-bottom: 20px;
}
.model-screen-cover {
    position: absolute;
    height: 250px;
    width: 100%;
    object-fit: cover;
}
@media (max-width: 850px) {
    .model-screen-artist-details {
        width: 100%;
        height: fit-content;
        flex-direction: column;
        justify-content: center;
    }
}
.model-screen-content {
    height: 100dvh;
    flex: 1;
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
    background-color: var(--accent-background-color);
}
@media (max-width: 850px) {
    .model-screen-content {
        width: 100%;
        flex-direction: column;
        padding-bottom: 100px;
        height: fit-content;
    }
}

.model-go-back {
    width: fit-content;
    padding: 20px;
    background-color: transparent;
    color: var(--primary-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 15px;
}
.model-go-back:hover {
    cursor: pointer;
}
@media (max-width: 850px) {
    .model-go-back {
        font-size: 12px;
    }
}

.model-name {
    font-size: 50px;
    color: var(--primary-text-color);
    margin: 0 0 0 40px;
}
@media (max-width: 850px) {
    .model-name {
        margin: 0px 0 0px 20px;
        font-size: 25px;
    }
}
.model-creator {
    font-size: 40px;
    color: var(--secondary-text-color);
    margin: 0 0 0 40px;
}
.model-creator:hover {
    cursor: pointer;
    text-decoration: underline;
}
@media (max-width: 850px) {
    .model-creator {
        margin: 0 0 0 20px;
        font-size: 20px;
    }
}
.model-links-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: 40px;
}
@media (max-width: 850px) {
    .model-links-row {
        width: calc(100% - 40px);
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 20px;
    }
}
.model-screen-download-button {
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    border-radius: 5px;
    border: none;
    color: var(--primary-text-color);
}
.model-screen-download-button:hover {
    cursor: pointer;
}
.model-screen-download-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.screen-link-pill {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-left: 40px;
    width: fit-content;
    gap: 10px;
    background-color: var(--background-color);
    border-radius: 10px;
}
@media (max-width: 850px) {
    .screen-link-pill {
        margin-left: 20px;
    }
}
.screen-link-pill:hover{
    cursor: pointer;
}
.screen-link-pill img{
    height: 20px;
}
@media (max-width: 850px) {
    .screen-link-pill img{
        height: 17.5px;
    }
}
.model-share-pill {
    display: flex;
    height: 40px;
    background-color: var(--background-color);
    border-radius: 10px;
    align-items: center;
    justify-content: center;
}
.model-share-pill:hover {
    cursor: pointer;
}
.model-share-pill img {
    height: 20px;
    padding: 12.5px;
}
@media (max-width: 850px) {
    .model-share-pill img {
        height: 20px;
        padding: 10px;
    }
}
.model-share-pill span {
    font-size: 13px;
    color: var(--primary-text-color);
    padding-right: 12.5px;
}

.model-stats-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 40px;
    height: 20px;
    padding: 10px 20px;
    width: fit-content;
    background-color: var(--background-color);
    border-radius: 10px;
}
.model-stats-row p, .model-stats-row h6 {
    margin: 0;
}
.model-stats-row h1 {
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-text-color);
}
.model-stats-row p {
    font-size: 15px;
    color: var(--primary-text-color);
}
@media (max-width: 850px) {
    .model-stats-row h1 {
        font-size: 15px;
    }
    .model-stats-row p {
        font-size: 10px;
    }
}
.model-stats-pill {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 10px;
}
.model-stats-pill h1, .model-stats-pill p {
    margin: 0;
}

.model-screen-content-section {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
}
@media (max-width: 850px) {
    .model-screen-content-section {
        padding-left: 0px;
    }
}
.model-screen-content-section h1{
    margin: 15px 0 0 15px;
    color: var(--primary-text-color);
    font-weight: normal;
    font-size: 20px;
}
