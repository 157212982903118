.song-list {
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 20px;
    flex-direction: row;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 20px;
    overflow: auto;
    overflow-x: scroll;
    scrollbar-width: none;
}
@media (max-width: 850px) {
    .song-list {
        height: 250px;
    }
}
