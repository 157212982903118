.song-screen {
    min-height: 100dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    align-items: center;
    background-color: var(--background-color);
}
.song-screen-header {
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.song-screen-back-button {
    background-color: transparent;
    padding: 20px 40px;
    color: var(--primary-text-color);
    border: none;
}
@media (max-width: 850px) {
    .song-screen-back-button {
        font-size: 15px;
        padding: 10px 20px;
    }
}

.song-screen-back-button:hover {
    cursor: pointer;
}
.song-highlight-section {
    background-color: var(--background-color);
    width: calc(100% - 80px);
    padding: 20px 40px;
    display: flex;
    flex-direction: row;
}
@media (max-width: 850px) {
    .song-highlight-section {
        width: 100%;
        padding: 20px 0;
        flex-direction: column;
    }
}
.song-highlight-cover {
    height: 250px;
    width: 250px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    border: none;
}
@media (max-width: 850px) {
    .song-highlight-cover {
        margin-left: auto;
        margin-right: auto;
    }
    .song-highlight-section .image-drop-area {
        margin: 0 auto;
    }
    .song-highlight-section, .account-username {
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        max-width: 90%;
        text-align: center;
    }
}
.song-highlight-details-section {
    flex: 1;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 15px;
}
@media (max-width: 850px) {
    .song-highlight-details-section {
        padding-top: 10px;
        align-items: center;
        padding-left: 0;
    }
}
.song-highlight-details-section p {
    margin: 0;
}
.song-screen-remix-identifier {
    color: var(--secondary-text-color);
}
@media (max-width: 850px) {
    .song-screen-remix-identifier {
        text-align: center;
        font-size: 12px;
        margin: 10px 0 5px 0 !important;
    }
}
.song-screen-title {
    font-size: 50px;
    color: var(--primary-text-color);
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
@media (max-width: 850px) {
    .song-screen-title {
        width: 80%;
        text-align: center;
        font-size: 20px;
    }
}
.song-screen-creator, .song-screen-creator-remix {
    font-size: 18px;
    color: var(--secondary-text-color);
}
@media (max-width: 850px) {
    .song-screen-creator, .song-screen-creator-remix {
        text-align: center;
        font-size: 15px;
    }
}
.song-screen-creator:hover {
    cursor: pointer;
    text-decoration: underline;
}

.song-details-section {
    width: calc(100% - 80px);
    padding: 20px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
@media (max-width: 850px) {
    .song-details-section {
        gap: 10px;
        width: calc(100% - 20px);
        padding: 10px 10px;
    }
}
.song-screen-play-button {
    height: 55px;
    width: 55px;
    background-color: #ff2e2e;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.song-screen-play-button:hover {
    cursor: pointer;
}
.song-screen-play-button img {
    height: 30px;
    filter: invert(1);
}
.song-screen-remix-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--fun-object-background);
}
.song-screen-remix-container:hover {
    cursor: pointer;
}
.song-screen-remix-container p {
    margin: 0;
    color: var(--primary-text-color);
    font-size: 14px;
}
.song-screen-heart-button:hover {
    cursor: pointer;
}
.song-screen-stream-count {
    font-size: 15px;
    color: var(--secondary-text-color);
    text-decoration: underline;
}
@media (max-width: 850px) {
    .song-screen-stream-count {
        font-size: 12px;
    }
}
.song-screen-content-section {
    width: calc(100% - 80px);
    overflow-x: scroll;
    gap: 30px;
    padding: 0 40px;
    display: flex;
    flex-direction: row;
}
@media (max-width: 850px) {
    .song-screen-content-section {
        flex-direction: column;
        width: calc(100% - 20px);
        padding: 0 10px 100px 10px;
    }
}
.song-screen-model-tile {
    padding: 15px;
    border-radius: 5px;
    background-color: var(--background-color);
    display: flex;
    flex-direction: row;
}
@media (max-width: 850px) {
    .song-screen-model-tile {
        width: calc(100% - 30px);
        flex-direction: column;
    }
}
.song-screen-model-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.song-screen-model-tile p {
    margin: 0;
}
.song-screen-model-title {
    color: var(--secondary-text-color);
}
@media (max-width: 850px) {
    .song-screen-model-title {
        font-size: 12px;
    }
}
.song-screen-model-name {
    color: var(--primary-text-color);
    font-weight: bold;
    font-size: 18px;
    max-width: 300px;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
}
.song-screen-model-name:hover {
    cursor: pointer;
    text-decoration: underline;
}
@media (max-width: 850px) {
    .song-screen-model-name {
        font-size: 15px;
    }
}
.song-screen-model-creator {
    color: var(--primary-text-color);
}
.song-screen-model-creator:hover {
    cursor: pointer;
    text-decoration: underline;
}
@media (max-width: 850px) {
    .song-screen-model-creator {
        margin-top: 5px !important;
        font-size: 13px;
    }
}
.song-screen-model-heart-button {
    /*margin-top: 10px;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    background-color: var(--accent-background-color);
    width: fit-content;
    padding: 5px 10px;
    border-radius: 5px;
}
.song-screen-model-heart-button:hover {
    cursor: pointer;
}
.song-screen-model-heart-button img {
    height: 30px;
}
.song-screen-model-heart-button p {
    margin: 0;
    font-weight: bold;
    color: var(--primary-text-color);
    font-size: 15px;
}
.song-screen-model-score-title {
    padding-top: 30px;
    color: var(--secondary-text-color);
    font-size: 15px;
}
@media (max-width: 850px) {
    .song-screen-model-score-title {
        padding-top: 10px;
    }
}

.song-screen-score-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    padding: 3px 10px;
    align-items: center;
    background-color: var(--accent-background-color);
    border-radius: 20px;
    gap: 10px;
    width: fit-content;
}
@media (max-width: 850px) {
    .song-screen-score-container {
        margin-top: 0px;
    }
}
.song-screen-green {
    background-color: #00c400;
}
.song-screen-score-container img {
    height: 18px;
    width: 18px;
}
.song-screen-green p {
    color: white;
}
.song-screen-green img {
    filter: brightness(0) invert(1);
}

.song-screen-model-songs {
    display: flex;
    flex-direction: row;
    margin-left: 40px;
}
@media (max-width: 850px) {
    .song-screen-model-songs {
        margin-top: 10px;
        flex-direction: row;
        margin-left: 0;
        max-width: calc(100% - 20px);
        overflow-x: scroll;
    }
}

.song-screen-model-song {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}
.song-screen-model-song .model-song-tile-cover{
    width: 200px;
    height: 200px;
}
@media (max-width: 850px) {
    .song-screen-model-song .model-song-tile-cover {
        width: 150px;
        height: 150px;
    }
}

.song-screen-model-song .model-song-title {
    width: 200px;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
    margin-top: 5px !important;
}
@media (max-width: 850px) {
    .song-screen-model-song .model-song-title {
        width: 150px;
    }
}

.song-screen-model-song .model-song-artist {
    margin-top: auto;
}
